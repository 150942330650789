import React, {FC, useContext, useEffect, useMemo, useState} from 'react';
import {graphql, PageProps} from 'gatsby';
import {
    ContentfulPage,
    ContentfulPageHome,
    contentfulPageKontakt,
    ContentfulPageNasiSpecjalisci,
    ContentfulPoradnia,
    ContentfulPoradniaConnection,
} from '../model/generated/graphql';
import {PageContentWrapper} from '../components/PageContentWrapper/PageContentWrapper';
import {Container} from '../components/Container/Container';
import {MemberCard} from '../components/MemberCard/MemberCard';
import styled from 'styled-components';
import {rem} from 'polished';
import {PageTransition} from '../components/PageTransition/PageTransition';
import {Chip} from '../components/Chip';
import {pxToRem} from '../utils/pxToRem';
import {ServicesHeightWrapper} from './rehabilitacja';
import {SectionCard, SectionImg, SectionTitle, TeleporadaContent, TeleporadaSection} from './index';
import {
    ContentfulRichTextGatsbyReference,
    renderRichText,
    RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import LocaleContext from '../context/Locale';
import Seo from '../components/Seo/Seo';

interface IndexQueryProps {
    contentfulPage: ContentfulPage;
    allContentfulPoradnia: ContentfulPoradniaConnection;
    contentfulPageKontakt: contentfulPageKontakt;
    contentfulPageHome: ContentfulPageHome;
}
type IndexPageProps = PageProps<IndexQueryProps>;

const RootIndex: FC<IndexPageProps> = ({ data, location }) => {
    const { title, pageReference } = data.contentfulPage;
    const { activeLocale } = useContext(LocaleContext);

    const params = new URLSearchParams(location.search);
    const query = useMemo(() => params.keys().next().value, [params]);

    const [team, setTeam] = useState((pageReference as ContentfulPageNasiSpecjalisci).team);

    const [activeFilter, setActiveFilter] = useState('all');

    const handleFilter = (e: ContentfulPoradnia['contentfulid']) => {
        setActiveFilter(e);
        if (e === 'all') {
            setTeam((data.contentfulPage.pageReference as ContentfulPageNasiSpecjalisci).team);
            return;
        }
        const filter = (pageReference as ContentfulPageNasiSpecjalisci).team.filter(({ division }) =>
            division.some(({ contentfulid }) => contentfulid === e),
        );

        setTeam(filter);
    };

    useEffect(() => {
        query && handleFilter(query);
    }, [query]);

    return (
        <>
            <Seo locale={activeLocale} seo={data.contentfulPage.pageReference.seo} />
            <PageContentWrapper title={title}>
                <Container>
                    <PageTransition>
                        <Filters>
                            <FiltersLabel>Specjalizacje</FiltersLabel>
                            <Chip
                                handleClick={handleFilter}
                                id={'all'}
                                label={'Wszystkie'}
                                active={activeFilter === 'all'}
                            />
                            {data.allContentfulPoradnia.edges.map((el) => {
                                return (
                                    <Chip
                                        active={activeFilter === el.node.contentfulid}
                                        key={el.node.id}
                                        handleClick={handleFilter}
                                        id={el.node.contentfulid}
                                        label={el.node.title}
                                    />
                                );
                            })}
                        </Filters>
                        <ServicesHeightWrapper>
                            <Members>
                                {team.map((data) => {
                                    return <MemberCard props={data} key={data.contentful_id} />;
                                })}
                            </Members>
                        </ServicesHeightWrapper>
                    </PageTransition>
                </Container>
                <TeleporadaSection page={'kontakt'}>
                    <Container>
                        <TeleporadaContent>
                            <SectionCard>
                                <div>
                                    <SectionTitle>{data.contentfulPageHome.teleporadaTytu}</SectionTitle>

                                    {renderRichText(
                                        data.contentfulPageHome
                                            .teleporadaOpis as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                                    )}
                                </div>
                            </SectionCard>
                            <SectionImg image={data.contentfulPageHome.teleporadaZdjcie.gatsbyImageData} alt="" />
                        </TeleporadaContent>
                    </Container>
                </TeleporadaSection>
            </PageContentWrapper>
        </>
    );
};

export const Filters = styled.div`
    margin-top: ${pxToRem(40)};
`;

export const FiltersLabel = styled.p`
    font-weight: 600;
    margin-bottom: 0;
    font-size: 1rem;
    text-transform: uppercase;
`;

const Members = styled.div`
    margin-top: ${rem(50)};
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: ${rem(50)};

    @media (max-width: ${rem(1400)}) {
        grid-gap: ${rem(25)};
    }

    @media (max-width: ${rem(800)}) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: ${rem(600)}) {
        grid-template-columns: 1fr;
    }
`;

export default RootIndex;

export const pageQuery = graphql`
    query TeamQuery($node_locale: String) {
        contentfulPage(contentful_id: { eq: "5crwPVnWTInYMNixg5hJ1C" }, node_locale: { eq: $node_locale }) {
            title
            pageReference {
                ... on ContentfulPageNasiSpecjalisci {
                    seo {
                        title
                        description
                        noIndex
                    }
                    contentful_id
                    id
                    title
                    team {
                        contentful_id
                        division {
                            id
                            contentfulid
                        }
                        name
                        specialization
                        availability {
                            contentful_id
                            day
                            timeFrom
                            timeUntil
                        }
                        cenaWizyty
                        avatar {
                            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
                        }
                        nfz
                    }
                }
            }
        }
        allContentfulPoradnia(filter: { node_locale: { eq: $node_locale } }, sort: { fields: title, order: ASC }) {
            edges {
                node {
                    title
                    id
                    contentfulid
                }
            }
        }
        contentfulPageKontakt(contentful_id: { eq: "4CI60KPsDdc3LGC7S3xH9k" }, node_locale: { eq: $node_locale }) {
            ...contact
        }
        contentfulPageHome(contentful_id: { eq: "2RUjuj71vLtS3N3TlqZcv4" }, node_locale: { eq: $node_locale }) {
            teleporadaTytu
            teleporadaZdjcie {
                contentful_id
                gatsbyImageData(layout: CONSTRAINED, quality: 90, height: 400)
            }
            teleporadaOpis {
                raw
            }
        }
    }
`;
//
// export const pageQuery = graphql`
//     query HomeQuery {
//         allContentfulBlogPost(sort: { fields: [publishDate], order: DESC } filter:{node_locale:{eq:"en-US"}}) {
//             edges {
//                 node {
//                     title
//                     slug
//                     publishDate(formatString: "MMMM Do, YYYY")
//                     tags
//                     heroImage {
//                         fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
//                             ...GatsbyContentfulFluid_tracedSVG
//                         }
//                     }
//                     description {
//                         childMarkdownRemark {
//                             html
//                         }
//                     }
//                 }
//             }
//         }
//         allContentfulPerson(
//             filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
//         ) {
//             edges {
//                 node {
//                     name
//                     shortBio {
//                         shortBio
//                     }
//                     title
//                     heroImage: image {
//                         fluid(
//                             maxWidth: 1180
//                             maxHeight: 480
//                             resizingBehavior: PAD
//                             background: "rgb:000000"
//                         ) {
//                             ...GatsbyContentfulFluid_tracedSVG
//                         }
//                     }
//                 }
//             }
//         }
//     }
// `
