import React, { FC, useContext, useEffect, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import {
    ContentfulPageRehabilitacja,
    ContentfulRehabilitacjaWpis,
    ContentfulRehabilitacjaWpisConnection,
    ContentfulRehabilitacjaWpisEdge,
} from '../model/generated/graphql';
import { PageContentWrapper } from '../components/PageContentWrapper/PageContentWrapper';
import { Container } from '../components/Container/Container';
import styled from 'styled-components';
import { rem } from 'polished';
import { borderRadius } from '../components/Post/Post';
import { PageRow } from '../components/PageRow/PageRow';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
    ContentfulRichTextGatsbyReference,
    renderRichText,
    RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import LocaleLink from '../components/LocaleLink/LocaleLink';
import { ROUTES } from '../utils/routes';
import { PageTransition } from '../components/PageTransition/PageTransition';
import { Chip } from '../components/Chip';
import { Filters, FiltersLabel } from './team';
import { pxToRem } from '../utils/pxToRem';
import LocaleContext from '../context/Locale';
import Seo from '../components/Seo/Seo';

interface QueryProps {
    contentfulPageRehabilitacja: ContentfulPageRehabilitacja;
    allContentfulRehabilitacjaWpis: ContentfulRehabilitacjaWpisConnection;
}
type Props = PageProps<QueryProps>;

type ServiceProps = {
    data: ContentfulRehabilitacjaWpis;
};

const Service: FC<ServiceProps> = ({ data }) => {
    const { title, description, image, slug } = data;

    return (
        <ServiceLink to={`${ROUTES.REHABILITACJA}/${slug}`}>
            <ServiceTile>
                <ServiceTitleWrapper>
                    <ServiceTitle>{data.title}</ServiceTitle>
                </ServiceTitleWrapper>
                {image && <ServiceImg image={image.gatsbyImageData} alt="" />}
            </ServiceTile>
        </ServiceLink>
    );
};
const ServiceImg = styled(GatsbyImage)`
    width: 100%;
    height: auto;
    border-radius: ${borderRadius};
    transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
`;
const ServiceLink = styled(LocaleLink)`
    position: relative;
    color: var(--color-text-primary);
    box-shadow: 0 ${rem(1)} ${rem(3)} rgba(0, 0, 0, 0.08), 0 ${rem(1)} ${rem(2)} rgba(0, 0, 0, 0.1);
    border-radius: ${borderRadius};
    &:hover ${ServiceImg} {
        transform: scale(1.2);
    }
    overflow: hidden;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
`;

const ServiceTile = styled.div`
    position: relative;
    border-radius: ${borderRadius};
    overflow: hidden;
`;

const ServiceTitle = styled.h3`
    z-index: 1;
    padding: 0 ${rem(24)};
    font-size: 1rem;
`;

const ServiceTitleWrapper = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
    font-family: sans-serif;
    text-align: center;
    line-height: 1;
    backdrop-filter: blur(${rem(5)});
    border-bottom-left-radius: ${borderRadius};
    border-bottom-right-radius: ${borderRadius};
    overflow: hidden;
`;

const RehabilitacjaPage: FC<Props> = ({ data, location }) => {
    const { title, description, seo } = data.contentfulPageRehabilitacja;
    const { activeLocale } = useContext(LocaleContext);

    const [team, setTeam] = useState(data.allContentfulRehabilitacjaWpis.edges);
    const [filters, setFilters] = useState([]);

    const { edges } = data.allContentfulRehabilitacjaWpis;

    useEffect(() => {
        let prepFilters = [];
        edges.forEach((el) => {
            if (!prepFilters.includes(el.node.category)) {
                prepFilters.push(el.node.category);
            }
        });
        setFilters(prepFilters.reverse());
    }, []);

    const [activeFilter, setActiveFilter] = useState('all');

    const handleFilter = (e: ContentfulRehabilitacjaWpisEdge['node']['category']) => {
        setActiveFilter(e);
        if (e === 'all') {
            setTeam(data.allContentfulRehabilitacjaWpis.edges);
            return;
        }
        const filter = data.allContentfulRehabilitacjaWpis.edges.filter(({ node }) => node.category === e);
        setTeam(filter);
    };

    return (
        <>
            <Seo locale={activeLocale} seo={data.contentfulPageRehabilitacja.seo} />
            <PageContentWrapper title={title}>
                <div style={{ marginBottom: 100 }}>
                    <Container>
                        <PageTransition>
                            <PageRow>
                                <Wrapper>
                                    {renderRichText(
                                        description as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                                    )}
                                </Wrapper>
                            </PageRow>
                            <PageRow>
                                <Filters>
                                    <FiltersLabel>Kategorie:</FiltersLabel>
                                    <ChipsWrapper>
                                        <Chip
                                            handleClick={handleFilter}
                                            id={'all'}
                                            label={'Wszystkie'}
                                            active={activeFilter === 'all'}
                                        />
                                        {filters.map((el) => {
                                            return (
                                                <Chip
                                                    active={activeFilter === el}
                                                    key={el}
                                                    handleClick={handleFilter}
                                                    id={el}
                                                    label={el}
                                                />
                                            );
                                        })}
                                    </ChipsWrapper>
                                </Filters>
                                <ServicesHeightWrapper>
                                    <ServicesWrapper>
                                        {team.map((item) => (
                                            <Service key={item.node.id} data={item.node} />
                                        ))}
                                    </ServicesWrapper>
                                </ServicesHeightWrapper>
                            </PageRow>
                        </PageTransition>
                    </Container>
                </div>
            </PageContentWrapper>
        </>
    );
};

const Wrapper = styled.div`
    margin-top: ${rem(50)};
`;

export const ServicesHeightWrapper = styled.div`
    min-height: ${pxToRem(800)};
`;

const ServicesWrapper = styled.div`
    margin-top: 50px;
    display: grid;
    grid-gap: 50px;
    grid-template-columns: 1fr 1fr 1fr;

    @media (max-width: ${rem(880)}) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: ${rem(600)}) {
        grid-template-columns: 1fr;
    }
`;

export const ChipsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    @media (max-width: ${rem(800)}) {
        justify-content: space-evenly;
    }
`;

export default RehabilitacjaPage;

export const pageQuery = graphql`
    query RehabilitacjaQuery($node_locale: String) {
        contentfulPageRehabilitacja(
            contentful_id: { eq: "5tU4gA9JdKioNju1gHTbzE" }
            node_locale: { eq: $node_locale }
        ) {
            id
            title
            seo {
                title
                description
                noIndex
            }
            description {
                raw
            }
        }
        allContentfulRehabilitacjaWpis(
            filter: { node_locale: { eq: $node_locale } }
            sort: { fields: title, order: ASC }
        ) {
            edges {
                node {
                    id
                    contentful_id
                    title
                    slug
                    category
                    image {
                        gatsbyImageData(
                            layout: CONSTRAINED
                            cropFocus: CENTER
                            placeholder: BLURRED
                            quality: 90
                            width: 400
                            height: 300
                        )
                    }
                    description {
                        raw
                    }
                }
            }
        }
        contentfulPageKontakt(contentful_id: { eq: "4CI60KPsDdc3LGC7S3xH9k" }, node_locale: { eq: $node_locale }) {
            ...contact
        }
    }
`;
